.menu {
    font-size: 14px;
    background-color: #fff;
    padding: 5px 0 5px 0;
    height: auto;
    margin: 0;
    position: absolute;
    list-style: none;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 20px 0 #ccc;
    box-shadow: 0 0 20px 0 #ccc;
    opacity: 1;
    transition: opacity 0.5s linear;
    text-align: left;
    z-index: 1000;
    border-radius: 5px;
}

.menu li {
    padding: 3px 15px;
}

.menu li:hover {
    cursor: pointer;
    opacity: 0.5;
}

.menu hr {
    color: lightgrey;
}