@import "../../style/CSS-variables.css";

$new-apt-bg: #ecf0f3;
$new-apt-border: #ecf0f3;


$day01: #fdfbfb;
$day02: #bdbdbd;
$week-box-bg: $day01 !important;

$day-bg: var(--neuro-background);
$day-border: 1px solid var(--neuro-background);
$day-text-color: var(--txt-color-secondary);

$hour-bg: var(--neuro-background);
$hour-text-color: var(--txt-color-secondary);
$hour-border: 1px solid var(--neuro-background);

$background-cells-bg: rgba(255, 255, 255, 0.95) !important;
$background-cells-border: 1px solid #ece9e9;

$price-border: 1px solid var(--neuro-background);
$price-bg: var(--neuro-background) !important;
$price-text-color: var(--txt-color-secondary);

$tatouage-bg: rgba(49, 197, 159, 0.65) !important;
$consultation-bg: rgba(197, 192, 192, 0.65) !important;
$retouche-bg: rgba(134, 224, 252, 0.65) !important;
$indisponible-bg: rgba(252, 24, 24, 0.65) !important;

$lunchtime-bg: #e0e0e0 !important;
$row_height: 4vh;
.weekBox {
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height $row_height;
  max-height: 100vh !important;
  overflow: hidden;
  border-radius: 20px;
  padding: 5px;
  box-sizing: border-box;
  background: #ecf0f3 !important;
  -webkit-appearance: none;
  -webkit-box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  width: 90%;
  margin: 10px auto auto auto;
}

@media (max-width: 380px) {
  .weekBox {
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.leftCorner {
  grid-column: 1;
  grid-row: 1;
  background-color: #ecf0f3 !important;
}

.backgroundCell:hover {
  cursor: pointer;
  opacity: 0.5;
}

.backgroundCell {
  background-color: $background-cells-bg;
  border: $background-cells-border;
}

.dayLabelCell {
  border: $day-border;
  background-color: $day-bg;
  color: $day-text-color;
  line-height: $row_height;
  font-size: 12px !important;
}

.hourCell {
  background-color: $hour-bg;
}

.hourCell p {
  display: inline-block;
  margin-top: -37%;
  top: -10px;
  position: relative;
  color: $hour-text-color;
  font-size: 12px;
}

.aptCell {
  font-size: small;
  font-weight: bold;
  border-bottom: solid 1px white;
  overflow: hidden;
  color: #2c2a2a;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aptCell div:nth-child(2),
.aptCell div:nth-child(3) {
  font-size: 11.5px;
  font-weight: lighter;
}

.aptCell:hover {
  cursor: pointer;
}

.newAptCell {
  border-radius: 0 !important;
  border: $new-apt-border;
  background-color: $new-apt-bg;
  color: var(--txt-color-secondary);
}

.bgSelected {
  background-color: cadetblue !important;
}

.tatouage {
  background-color: $tatouage-bg
}

.consultation {
  background-color: $consultation-bg
}

.retouche {
  background-color: $retouche-bg
}

.indisponible {
  background-color: $indisponible-bg
}


.lunchTime {
  background-color: $lunchtime-bg;
}

.error-input {
  border-bottom: #dc1e1e;
  color: #dc1e1e;
}

.firstnameOnCell {
  color: white;
  width: fit-content;
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
}

.patternOnCell em{
  font-family: arial, serif;
  font-weight: bold;
}

.phoneOnCell {
  font-family: arial, serif;
  line-height: 20px;
}

.priceCell {
  border: $price-border;
  background-color: $price-bg;
  color: $price-text-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8em;

  z-index: 10; // Contourne les problèmes de rdv qui dépassent 19h et qui se superposent à cette case
}

.priceCell:hover {
  opacity: 1;
  cursor: pointer;
}

@media (max-width: 900px) {
  .weekBox {
    width: 97%;
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .hourCell {
    padding-right: 5px;
  }
  .hourCell p, .dayLabelCell {
    font-size: 10px!important;
  }

  .dayLabelCell {
    line-height: normal;
  }
}
@media (max-width: 1024px) {
  .weekBox {
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .hourCell {
    padding-right: 5px;
  }
  .aptCell div:nth-child(1) {
    font-size: 10.5px !important;
  }
  .aptCell div:nth-child(2), .aptCell div:nth-child(3) {
    font-size: 9.5px !important;
  }
}

.toggleCA {
  color: var(--txt-color-secondary);
  font-size: 11px;
  display: block;
  width: 90%;
  margin: auto;
  text-align: right;
  padding-right: 14px;
  padding-top: 9px;;
}

.toggleCA:hover {
  cursor: pointer;
  opacity: 0.5;
}