@import "../../style/CSS-variables.css";

.form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-container .form-box,
.form-container .artist-list {
  border-radius: var(--neuro-radius);
  background: var(--neuro-background);
  -webkit-appearance: none;
  -webkit-box-shadow: var(--neuro-shadow);
  box-shadow: var(--neuro-shadow);
  margin: 20px;
  padding: 20px;
}

.form-container .artist-list {
  flex: 2;
}

.artist-details {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  align-items: center;
  text-align: center;
}

.artist-details > div {
  text-align: center;
  flex: 1;
  margin: auto 30px
}

.artist-details button {
  width: 50px;
  margin: auto;
}

.form-box {
  flex: 1;
  height: fit-content;
}

.form-box .inputs {
  text-align: left;
  margin-top: 30px;
}

.form-box label, .form-box input, .form-box button {
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.form-box input {
  margin-top: 20px;
  background: var(--color-primary);
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: var(--neuro-radius);
  -webkit-appearance: none;
  -webkit-box-shadow: var(--neuro-revers-shadow);
  box-shadow: var(--neuro-revers-shadow);
}

.form-box button {
  margin-top: 20px;
  background: var(--color-secondary);
  height: 40px;
  border-radius: var(--neuro-radius);
  cursor: pointer;
  font-weight: 900;
  -webkit-appearance: none;
  -webkit-box-shadow: var(--neuro-shadow);
  transition: 0.5s;
  color: white;
}

.form-box button:hover {
  box-shadow: none;
}

.form-box .radio-groups {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-around;
}
