.loader-box {
  width: 100vw;
  height: 100vh;
  background: rgba(19, 19, 19, 0.62);
  color: white;
  position: absolute;
  z-index: 99000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}