@import "/src/style/CSS-variables.css";

.appointment {
    background-color: rgba(49, 197, 159, 0.65) !important
}

.consultation {
    background-color: rgba(197, 192, 192, 0.65) !important
}

.touchUp {
    background-color: rgba(134, 224, 252, 0.65) !important
}

.unavailable {
    background-color: rgba(252, 24, 24, 0.65) !important
}

.scheduler-week-title {
    text-align: center !important;
    margin: auto;
}

.scheduler-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    flex-wrap: wrap;
    margin: auto;
}

.scheduler-datepicker {
    background: transparent;
    border: none;
    display: none;
}

.noteBox {
    border-radius: 20px;
    background: #ecf0f3 !important;
    -webkit-appearance: none;
    -webkit-box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    width: 90%;
    margin: 30px auto 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;

}

.noteBox h3 {
    margin: 5px auto 10px auto;
    color: var(--txt-color-secondary);
}

.noteBox textarea {
    height: 200px;
    margin-bottom: 10px;
    border-radius: 15px!important;
}

.newMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.newMessage input, .noteBox textarea {
    display: block;
    border: none;
    outline: none;
    box-sizing: border-box;
    background: var(--color-primary);
    padding: 10px;
    padding-left: 20px;
    font-size: 14px;
    border-radius: var(--neuro-radius);
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-revers-shadow);
    box-shadow: var(--neuro-revers-shadow);
}

.newMessage input {
    width: 100%;
    margin-left: 20px;
}

#week-start-selector:focus {
    outline: none;
}

.legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    /*margin: auto;*/
    justify-content: left;
    flex-wrap: wrap;
    box-sizing: border-box;
    background: var(--neuro-background);
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-shadow);
    box-shadow: var(--neuro-shadow);
    border-radius: var(--neuro-radius);
    font-size: 13px;
    padding: 5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: 0;
}

.legend > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 5px 5px;
}

.legend > div > div {
    border-radius: 3px;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    margin-left: 3px;
}

.legend > div > small {
    margin-right: 3px;
}

.scheduler-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    align-items: start;
}

.scheduler-buttons label {
    vertical-align: center;
    line-height: 40px;
}

.scheduler-buttons > button,
.scheduler-buttons > div,
.scheduler-buttons > label {
    margin-top: 10px;
}

.fakeBtn {
    height: 40px;
    cursor: pointer;
    font-weight: 900;
    transition: 0.5s;
    padding: 0 8px;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-shadow);
    box-shadow: var(--neuro-shadow);
    border-radius: var(--neuro-radius);
    color: var(--txt-color-secondary);
    text-transform: uppercase;
    font-size: 11px!important;
    letter-spacing: 1px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fakeBtn button{
    color: var(--txt-color-secondary);
    font-size: 11px!important;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: auto 3px;
    border: none;
    font-weight: bold;
    background-color: transparent;
    min-width: 40px;
    height: 100%;
}
.today {
    min-width: 120px;
}
.fakeBtn button:hover, .fakeBtn today:hover{
    transition-duration: 0.2s;
    cursor: pointer;
    opacity: 0.5;
    color: var(--txt-color-primary);
}

.fakeBtn .separator {
    display: inline-block;
    width: 1px;
    height: 100%;
    border-left: 1px solid #ededf1;
    background-color: #eaeaea;
    border-right: 1px solid var(--txt-color-primary);
}
.datepicker-toggle {
    display: inline-block;
    position: relative;
}

.datepicker-toggle-button {
    font-size: 15px;
}

.datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
}



#scroll-container {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--neuro-background);
    overflow: hidden;
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-shadow);
    box-shadow: var(--neuro-shadow);
}

#scroll-container:hover {
    cursor: pointer;
}

#scroll-text {
    color: var(--txt-color-secondary);
    width: max-content;
    min-width: 100%;
    text-align: left;
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 20s linear infinite;
    -webkit-animation: my-animation 20s linear infinite;
    animation: my-animation 20s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@media (max-width: 1024px) {
    .scheduler-week-title {
        display: none;
    }
}


@media (max-width: 900px) {
    .scheduler-header {
        min-width: 97%!important;

    }
    .legend {
        margin: auto!important;
    }
    .noteBox textarea {
        height: 130px;
    }
}

@media (max-width: 648px) {
    .scheduler-buttons {
        justify-content: left;
    }
}