@import "/src/style/CSS-variables.css";

.login-container {
    width: 350px;
    height: 500px;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    background: var(--color-primary);
    box-shadow: var(--neuro-shadow);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-container .brand-logo {
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: var(--neuro-shadow);
    color: var(--color-secondary);
    font-size: 60px;
    line-height: 100px;
}

.login-container .brand-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 1.8rem;
    color: var(--color-secondary);
    letter-spacing: 1px;
}

.login-container .inputs {
    text-align: left;
    margin-top: 30px;
}

.login-container label, .login-container input, .login-container button {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
}

.login-container label {
    margin-bottom: 4px;
}

.login-container label:nth-of-type(2) {
    margin-top: 12px;
}

.login-container input::placeholder {
    color: gray;
}

.login-container input {
    background: var(--color-primary);
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: var(--neuro-radius);
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-revers-shadow);
    box-shadow: var(--neuro-revers-shadow);
}

.login-container .inputs button {
    margin-top: 20px;
    background: var(--color-secondary);
    height: 40px;
    border-radius: var(--neuro-radius);
    cursor: pointer;
    font-weight: 900;
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-shadow);
    box-shadow: var(--neuro-shadow);
    transition: 0.5s;
    color: white;
}

.login-container .inputs button:hover {
    box-shadow: none;
}

.login-container h1 {
    position: absolute;
    top: 0;
    left: 0;
}