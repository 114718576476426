@import "/src/style/CSS-variables.css";

.confirm-modal {
    position: absolute; /* Stay in place */
    z-index: 5500; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 2000px; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.confirm-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 40px;
}

@media (max-width: 700px) {
    button {
        margin-bottom: 10px;
    }
}

.confirm-modal-content hr {
    width: 25vw;
    border: none;
    border-top: 1px solid grey;
}

.confirm-modal-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: fit-content;
    max-width: 70vw;
    z-index: 50000;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 5px 80px 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 800px) {
    .confirm-modal-content {
        padding: 5px 30px 30px 30px;
    }
}

.confirm-modal-body {
    min-width: 30vw;
}

.delete-btn {
    background-color: #be5f5f;
    color: #ece9e9;
}

.delete-btn:hover {
    background-color: #be5f5f;
    color: #ece9e9;
    opacity: 0.5;
}