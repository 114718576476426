@import "/src/style/CSS-variables.css";

.sync-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media only screen and (max-width: 900px) {
  .sync-container {
    flex-direction: column;
  }
}
.sync-disclaimer {
  text-align: left;
  padding: 0 35px;
  color: var(--txt-color-secondary);
}
.inputCA {
  width: 40%;
}
.inputCBDiv {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greyLabel {
  color: var(--txt-color-secondary)!important;
}
.blackLabel {
  font-weight: bold;
}
.selectBox {
  width: 45%;
}
.greyCA {
  color: var(--txt-color-secondary)!important;
}
.blackCA {
  font-weight: bold;
}

.greyLabel, .blackLabel {
  width: 50%;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}
.greyLabel:hover, .blackLabel:hover {
  cursor: pointer;
}
.sync-disclaimer p {
  margin-top: 40px;
  margin-bottom: 40px;
}
.sync-container h3 {
  color: var(--color-secondary);
}
.sync-form, .export-form {
  flex: 1;
  max-width: 800px;
  padding: 15px;
  -webkit-appearance: none;
  -webkit-box-shadow: var(--neuro-shadow);
  box-shadow: var(--neuro-shadow);
  border-radius: var(--neuro-radius);
  background: var(--neuro-background);
  height: fit-content;
  margin: 20px;

}
.sync-input-group {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: auto auto 20px;
}
@media only screen and (max-device-width : 1200px){
  .columnDirectionOnSmartphone {
    flex-direction: column;
  }
  .selectBox {
    width: 100%;
  }
  select {
    max-width: 200px;
    margin: 10px auto auto;
  }
}
.export-form {}

.sync-container button {
  margin: auto;
  background:  var(--color-secondary);
  color: var(--txt-color-primary);
}

.sync-container input {
  background: var(--color-primary);
  padding: 4px 4px 4px 20px;
  font-size: 14px;
  border: none;
  border-radius: var(--neuro-radius);
  /*box-shadow:-4px -2px 4px 0px #d1d9e6,*/
  /*4px 2px 8px 0px #fff, -8px -4px 8px 0px #ffffff,*/
  /*8px 4px 12px 0px #d1d9e6;*/
  //-webkit-appearance: none;
  -webkit-box-shadow: var(--neuro-revers-shadow);
  box-shadow: var(--neuro-revers-shadow);
  margin: 5px;
}



/* indicator & text */
.checkbox-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
  margin-right: 15px;
}

.checkbox-text {
  margin-left: 16px;
  opacity: .6;
  transition: opacity .2s linear, transform .2s ease-out;
}

.indicator {
  position: relative;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -webkit-box-shadow:
          -8px -4px 8px 0px #ffffff,
          8px 4px 12px 0px #d1d9e6;
  box-shadow:
          -8px -4px 8px 0px #ffffff,
          8px 4px 12px 0px #d1d9e6;
  overflow: hidden;
}

.indicator::before,
.indicator::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

.indicator::before {
  -webkit-appearance: none;
  -webkit-box-shadow:
          -4px -2px 4px 0px #d1d9e6,
          4px 2px 8px 0px #fff;
  box-shadow:
          -4px -2px 4px 0px #d1d9e6,
          4px 2px 8px 0px #fff;
}

.indicator::after {
  background-color: #ecf0f3;
  -webkit-appearance: none;
  -webkit-box-shadow:
          -4px -2px 4px 0px #fff,
          4px 2px 8px 0px #d1d9e6;
  box-shadow:
          -4px -2px 4px 0px #fff,
          4px 2px 8px 0px #d1d9e6;
  transform: scale3d(1, 1, 1);
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

input[type="checkbox"]:checked ~ .checkbox-label .indicator::after {
  transform: scale3d(.975, .975, 1) translate3d(0, 10%, 0);
  opacity: 0;
  color: #1DA1F2;
}

input[type="checkbox"]:focus ~ .checkbox-label .checkbox-text {
  transform: translate3d(8px, 0, 0);
  opacity: 1;
}

.checkbox-label:hover .checkbox-text {
  opacity: 1;
}