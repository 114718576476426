:root {
    --color-primary: #ecf0f3;
    --color-secondary: #1DA1F2;
    --color-error: #ec4b4b;
    --txt-color-primary: white;
    --txt-color-secondary: #838383;
    --txt-color-main: #2c2a2a;
    --txt-color-error: #ec4b4b;
    --txt-color-disabled: #9f9f9f;
    --neuro-shadow: 5px 5px 10px #c9cccf, -5px -5px 10px #ffffff;
    --neuro-revers-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
    --neuro-radius: 29px;
    --neuro-background: #ecf0f3;
}