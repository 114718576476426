@import "/src/style/CSS-variables.css";

.creation-modal {
    position: absolute; /* Stay in place */
    z-index: 5500; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 2000px; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.creation-modal-content {
    z-index: 50000;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background: var(--neuro-background);
    border-radius: var(--neuro-radius);
    width: -moz-fit-content;
    width: fit-content;
    padding: 17px 25px 30px 25px;
    max-width: 80%;
}


.creation-modal-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.creation-modal-body > div{
    flex: 1;
    padding: 0 10px;
}

.hour-select {
    text-align: center;
    border: none;
    font-weight: bold;
    color: var(--color-secondary);
}

.ml-10 {margin-left: 10px}

.apt-input-group {
    display: flex;
    flex-direction: column;
    height: fit-content !important;
}

.apt-input-group > div:nth-child(1) {
    flex: 1;
    text-align: left;
    font-size: 0.9rem;
    color: var(--txt-color-secondary);
    margin-bottom: 5px;
    margin-top: 5px;
}

.apt-input-group > div:nth-child(2) {
    flex: 4;
    font-size: 0.8rem !important;
}


.apt-input-group input[type="text"],
.apt-input-group input[type="number"],
.apt-input-group select,
.apt-input-group textarea{
    border: none;
    background-color: transparent;
    text-align: center;
    border-bottom: 1px solid #adadad;
    height: 25px;
    width: 90%;
}


.apt-input-group textarea{
    height: inherit;
}

.apt-input-group input:focus,
.apt-input-group textarea{
    resize: none;
    outline: none;
    border-bottom: 2px solid var(--color-secondary);
}

.radio-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.radio-group > div {
    width: 33%;
    text-align: left;
}

/* DATES */

.datePickerInput {
    border: 2px solid white;
}

.creation-modal-content input[type="date"] {
     border: none;
     font-weight: bolder;
     font-size: 16px;
     background-color: transparent;
     color: var(--color-secondary);
 }
.creation-modal-content input[type="date"]:hover,
.creation-modal-content select:hover {
    cursor: pointer;
}
.apt-input-group input:disabled {
    background-color: #f6f5f5;
}

.unstyled {
    -webkit-appearance: none;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

/*.unstyled::-ms-clear,*/
/*.unstyled::-webkit-clear-button,*/
/*.unstyled::-webkit-inner-spin-button,*/
/*.unstyled::-webkit-calendar-picker-indicator,*/
/*.unstyled::-moz-clear*/
/*{*/
/*    display: none;*/
/*}*/

/* ERROR*/

.modal-error-message {
    color: var(--txt-color-error)!important;
    margin: 10px 0 3px 0;
    font-size: 12px;
}

.input-error {
    color: var(--txt-color-error)!important;
}


/* BUTTONS
*/
.btn-creation {
    margin: 10px !important;
}

.creation-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.creation-modal-content input[type="text"], .creation-modal-content input[type="number"], .creation-modal-content textarea  {
    background: var(--color-primary);
    font-size: 14px;
    border: none;
    border-radius: var(--neuro-radius);
    -webkit-appearance: none;
    -webkit-box-shadow: var(--neuro-revers-shadow);
    box-shadow: var(--neuro-revers-shadow);
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}
.apt-input-group > div:first-child {
    font-size: 12px;
}
input[type="radio"] {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1e-5;
    pointer-events: none;
}

/* indicator & text */
.radio-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #394a56;
    margin-right: 10px;
    font-size: 12px;
}

.radio-text {
    margin-left: 8px;
    opacity: .6;
    transition: opacity .2s linear, transform .2s ease-out;
}

.indicator {
    position: relative;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    -webkit-box-shadow:
            -8px -4px 8px 0 #ffffff,
            8px 4px 12px 0 #d1d9e6;
    box-shadow:
            -8px -4px 8px 0 #ffffff,
            8px 4px 12px 0 #d1d9e6;
    overflow: hidden;
    margin-left: 10px;
}

.indicator::before,
.indicator::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    border-radius: 50%;
}

.indicator::before {
    -webkit-appearance: none;
    -webkit-box-shadow:
            -4px -2px 4px 0 #d1d9e6,
            4px 2px 8px 0 #fff;
    box-shadow:
            -4px -2px 4px 0 #d1d9e6,
            4px 2px 8px 0 #fff;
}

.indicator::after {
    background-color: #ecf0f3;
    -webkit-appearance: none;
    -webkit-box-shadow:
            -4px -2px 4px 0 #fff,
            4px 2px 8px 0 #d1d9e6;
    box-shadow:
            -4px -2px 4px 0 #fff,
            4px 2px 8px 0 #d1d9e6;
    transform: scale3d(1, 1, 1);
    transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

input[type="radio"]:checked ~ .radio-label .indicator::after {
    transform: scale3d(.975, .975, 1) translate3d(0, 10%, 0);
    opacity: 0;
}
input[type="radio"]:checked ~ label {
    color: var(--color-secondary);
    font-weight: bolder;
}
input[type="radio"]:focus ~ .radio-label .radio-text {
    transform: translate3d(8px, 0, 0);
    opacity: 1;
}

.creation-modal-hour-warning button:hover{
    cursor: pointer;
    opacity: .5;
}
.creation-modal-hour-warning button{
    border : none;
    background-color: transparent;
    font-weight: bold;
    color: var(--txt-color-error);
}

.creation-modal-hour-warning {
    max-width: 100%;
    font-size: 12px;
    color: var(--txt-color-error);
}

.radio-label:hover .radio-text {
    opacity: 1;
}

.creation-modal-title {
    margin: 10px 6px 5px 6px;
}

@media (max-width : 380px) {
    .creation-modal-title {
        font-size: .8em!important;
    }
    .modal-title-date-input {
        font-size: 1.3em!important;
    }
}


.creation-modal .btn {
    font-size: 10px!important;
    padding: 3px 13px;
}
.creation-modal hr {
    height: 1px;
    border: none;
    border-top: 1px solid var(--txt-color-disabled);
}