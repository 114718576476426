@import "/src/style/CSS-variables.css";

$color2: #ffffff;
$color1: #2c2a2a;
$nav-height: 80px;

.header {
  display: flex;
  background: $color2;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: $nav-height;
  position: -webkit-sticky;
  position: sticky;
  z-index: 300;
  top: 0px;
  background: #ecf0f3;
  -webkit-appearance: none;
  -webkit-box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;

}

.logo-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.logo-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.link-box {
  width: 200px;
}

.link-box > .nav-link {
  line-height: 100%;
  text-decoration: none;
  color: $color1;
}

.link-box-red > .nav-link {
  color: var(--txt-color-error)
}

.link-box > .nav-link:hover {
  cursor: pointer;
  color: darkgrey;
}

.nav-avatar {
  margin-left: 20px;
  float: left;
  height: 50px;
  max-width: 50px;
  width: 50px;
  line-height: 50px;
  color: white !important;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-avatar-label {
  position: relative;
  left: -25px;
  padding: 5px 15px;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 12px;
  color: white
}

.nav-avatar img {
  width: 80%;
  height: 80%;
  filter: invert(160%);
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-left: 20px;
  float: left;
  height: 50px;
  max-width: 50px;
  width: 50px;
  line-height: 50px;
}

.logo:hover, .nav-avatar, .nav-avatar-label{
  cursor: pointer;
}

.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
  color: $color1 !important;
  text-decoration: none;
  margin-left: auto;
}

.mobile-option {
  display: none;
}

.option {
  list-style: none;
  color: $color1 !important;
  text-decoration: none;
}

.option :hover {
  color: $color1;
}

.mobile-menu {
  display: none !important;
}

@media (max-width: 1120px) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
  }
  .logo-nav ul {
    flex-direction: column;
  }

  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: $nav-height;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
  .nav-options.active {
    background: $color2;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    margin: 0px;
  }

  .menu-icon {
    width: 28px !important;
    height: 28px !important;
    color: $color1 !important;
    margin-right: 20px;
  }
  .menu-icon:hover {
    cursor: pointer;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 10vw;
    padding: 30px 0px;
  }

  .mobile-menu {
    display: block !important;
  }
}
