@import "/src/style/CSS-variables.css";

.artists {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
}

.artists > div {
    flex: 1;
    padding: 20px;
}
.artist-name {
    padding: 10px 20px;
    height: 50px;
    font-size: 14px;
    border-radius: var(--neuro-radius);
    background: var(--neuro-background);
    -webkit-appearance: none;
    -webkit-box-shadow:  var(--neuro-shadow);
    box-shadow:  var(--neuro-shadow)
}
.artist {
    width: 120px;
    height: 120px;
    line-height: 120px;
    margin: auto;
    border-radius: 100px;
    -webkit-appearance: none;
    -webkit-box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    font-size: 2rem;
    color: #ece9e9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.artist img {
    width: 80%;
    height: 80%;
    filter: invert(160%);
}

.artist:hover {
    transition: 0.3s;
    opacity: 0.5;
    cursor: pointer;
}

@keyframes myAnim {
    0% {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}